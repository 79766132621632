import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import RenderProduct from "./RenderProduct";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Prices from "components/Prices";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { MdOutlineDeleteOutline, MdOutlineModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useCartDataProvider } from "../../contaxt/CartProductContext";
import NcInputNumber from "components/NcInputNumber";

interface OrderSummaryProps {
  products: any[];
  reOrderData: any;
  setHistoryOrders: Dispatch<SetStateAction<undefined>>;
  setsubTotal: Dispatch<SetStateAction<number>>;
  searchProduct: string;
  calculatingTotalPrice: (item: any) => number;
  merchantname: string;
  toggleAccordion: () => void;
  isAccordionOpen: boolean;
  selectTime: boolean;
  pickupTime: string;
  handlePickupTimeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleAsapChange: () => void;
  timeOptions: string[] | null | undefined;
  isAsap: boolean;
  handleTipSelection: (tip: string) => void;
  selectedTip: string;
  customTip: number;
  handleCustomTipChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage: string;
  carterror: string
  NoteContent: () => React.ReactNode;
  allProductPrice: number;
  taxDisplayList: string[];
  taxDisplayTotal: number[];
  finalTip: (selectedTip: string) => number;
  storedOrderFee: number;
  storedOrderFeeLabel: string;
  totAmt: number;
  isPaymentProcessing: boolean;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  products,
  reOrderData,
  setHistoryOrders,
  setsubTotal,
  searchProduct,
  calculatingTotalPrice,
  merchantname,
  toggleAccordion,
  isAccordionOpen,
  selectTime,
  pickupTime,
  handlePickupTimeChange,
  handleAsapChange,
  timeOptions,
  isAsap,
  handleTipSelection,
  selectedTip,
  customTip,
  handleCustomTipChange,
  errorMessage,
  carterror,
  NoteContent,
  allProductPrice,
  taxDisplayList,
  taxDisplayTotal,
  finalTip,
  storedOrderFee,
  storedOrderFeeLabel,
  totAmt,
  isPaymentProcessing,
}) => {
  const [items, setItems] = useState(reOrderData?.orderItems || null);
  console.log(items, reOrderData, "itemssummary");
  const navigate = useNavigate();
  const { dispatch }: any = useCartDataProvider();
  const handleIncrement = (index: number) => {
    console.log("enter123");
    const newItems = [...items];
    newItems[index].quantity += 1;
    setItems(newItems);
    calculateSubtotal(newItems);
  };

  const handleDecrement = (index: number) => {
    const newItems = [...items];
    if (newItems[index].quantity > 1) {
      newItems[index].quantity -= 1;
      setItems(newItems);
      calculateSubtotal(newItems);
    }
  };

  const calculateSubtotal = (items: any[]) => {
    let subtotal = allProductPrice;
    items.forEach((item) => {
      subtotal +=
        item.item.onlinePrice * item.quantity +
        (item.orderItemModifiers?.reduce(
          (acc: any, modifier: any) =>
            acc + modifier.modifierItem.onlinePrice * item.quantity,
          0
        ) || 0);
    });
    return subtotal;
  };

  const subtotal = reOrderData ? calculateSubtotal(items) : allProductPrice;
  console.log(subtotal, totAmt, allProductPrice, "checktotals");
  const totalAmount = reOrderData
    ? (totAmt + subtotal + reOrderData.tax - allProductPrice).toFixed(2)
    : totAmt.toFixed(2);
  useEffect(() => {
    setHistoryOrders(items);
    setsubTotal(subtotal);
  }, [items, subtotal]);
  console.log(taxDisplayList, taxDisplayTotal, "tax2323");
  return (
    <div className="w-full lg:w-[36%] ">
      <h3 className="text-lg font-semibold">Order summary</h3>

      <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
        <>
          {carterror && <p className="text-red-500 mt-2">{carterror}</p>}
          {products
            ?.filter((item: any) =>
              item.name.toLowerCase().includes(searchProduct.toLowerCase())
            )
            .map((item: any, index: any) => (
              <RenderProduct
                key={index}
                item={item}
                index={index}
                calculatingTotalPrice={calculatingTotalPrice}
                merchantname={merchantname ?? `${merchantname}`}
              />
            ))}
        </>
        {reOrderData &&
          reOrderData.orderItems
            ?.filter((item: any) =>
              item.item.name.toLowerCase().includes(searchProduct.toLowerCase())
            )
            .map((item: any, index: any) => {
              let totalPrice =
                item.item.onlinePrice * item.quantity +
                (item.orderItemModifiers?.reduce(
                  (acc: any, modifier: any) =>
                    acc + modifier.modifierItem.onlinePrice * item.quantity,
                  0
                ) || 0);
              return (
                <div
                  key={index}
                  className="relative flex py-4 sm:py-5 xl:py-6 first:pt-0 last:pb-0"
                >
                  <div className="ml-2 sm:ml-3 flex flex-1 flex-col">
                    <div>
                      <div className="flex justify-between ">
                        <div className="flex-[1.5] ">
                          <h3 className="text-base font-semibold">
                            {" "}
                            {item.item.name}
                          </h3>
                          <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                            <div className="flex items-center space-x-1.5"></div>
                            <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                            <div className="flex items-center space-x-1.5"></div>
                          </div>

                          <div className="mt-3 flex justify-between w-full sm:hidden relative">
                            <div className="sm text-center relative">
                              {/* <NcInputNumber
                                className="relative z-40"
                                quantity={item.quantity}
                                id={item.item.id}
                                modifierChecked={item.modifierChecked}
                                item={item.item}
                              /> */}
                              <div className="nc-NcInputNumber flex items-center justify-between space-x-5 relative z-20">
                                <div
                                  className={`nc-NcInputNumber__content flex items-center justify-between w-[104px] sm:w-28`}
                                >
                                  <button
                                    className="w-6 h-6 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 dark:hover:border-neutral-400 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                                    type="button"
                                    onClick={() => {
                                      handleDecrement(index);
                                      totalPrice -= item.item.onlinePrice;
                                    }}
                                    disabled={item.quantity <= 1}
                                  >
                                    <MinusIcon className="w-4 h-4" />
                                  </button>
                                  <span className="select-none block flex-1 text-center leading-none">
                                    {item.quantity}
                                  </span>
                                  <button
                                    className="w-6 h-6 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 dark:hover:border-neutral-400 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                                    type="button"
                                    onClick={() => {
                                      handleIncrement(index);
                                      totalPrice += item.item.onlinePrice;
                                    }}
                                    disabled={false}
                                  >
                                    <PlusIcon className="w-4 h-4" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <Prices
                              contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-base font-semibold h-full"
                              onlinePrice={totalPrice}
                            />
                          </div>
                        </div>

                        <div className="hidden sm:block text-center relative">
                          <div className="hidden sm:block text-center relative">
                            <div className="nc-NcInputNumber flex items-center justify-between space-x-5 relative z-20">
                              <div
                                className={`nc-NcInputNumber__content flex items-center justify-between w-[104px] sm:w-28`}
                              >
                                <button
                                  className="w-6 h-6 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 dark:hover:border-neutral-400 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                                  type="button"
                                  onClick={() => {
                                    handleDecrement(index);
                                    totalPrice -= item.item.onlinePrice;
                                  }}
                                  disabled={item.quantity <= 1}
                                >
                                  <MinusIcon className="w-4 h-4" />
                                </button>
                                <span className="select-none block flex-1 text-center leading-none">
                                  {item.quantity}
                                </span>
                                <button
                                  className="w-6 h-6 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 dark:hover:border-neutral-400 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                                  type="button"
                                  onClick={() => {
                                    handleIncrement(index);
                                    totalPrice += item.item.onlinePrice;
                                  }}
                                  disabled={false}
                                >
                                  <PlusIcon className="w-4 h-4" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="hidden flex-1 sm:flex justify-end text-base font-semibold">
                          <Prices onlinePrice={totalPrice} className="mt-0.5" />
                        </div>
                      </div>
                    </div>

                    {item.orderItemModifiers &&
                      item.orderItemModifiers.length > 0 && (
                        <div className="mt-2">
                          {item.orderItemModifiers.map(
                            (modifier: any, modifierIndex: any) => (
                              <div
                                key={modifierIndex}
                                className="flex flex-col"
                              >
                                <h3 className="text-xs flex justify-between pb-2">
                                  <span>{modifier.modifierItem.name}</span>
                                  <span className="text-slate-900 dark:text-slate-200">
                                    ${modifier.modifierItem.onlinePrice}
                                  </span>
                                </h3>
                              </div>
                            )
                          )}
                        </div>
                      )}
                  </div>
                </div>
              );
            })}
      </div>

      {/* <div className="mt-10">
        <div
          className="flex justify-between items-center py-3 text-sm font-semibold text-slate-700 dark:text-slate-200 border-b border-slate-200/70 dark:border-slate-700 cursor-pointer"
          onClick={toggleAccordion}
        >
          <span>Pickup Time</span>
          <button
            className="px-2 py-1 text-sm font-semibold text-slate-700 dark:text-slate-200 rounded"
            type="button"
          >
            {isAccordionOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </button>
        </div>
        {isAccordionOpen && (
          <div className="mt-4 text-sm text-slate-500 dark:text-slate-400">
            <p>Select your preferred pickup time:</p>
            <div className="mt-2 ">
              <input type="radio" checked={selectTime} className="mr-2" />
              <select
                value={pickupTime}
                onChange={handlePickupTimeChange}
                // disabled={isAsap}
                className="mt-2 p-1 border border-slate-300 dark:border-slate-600 rounded"
              >
                <option value="" className="block">
                  Select a time{" "}
                </option>
                {timeOptions &&
                  timeOptions.map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
              </select>
              <label className="block mt-3">
                <input
                  type="radio"
                  checked={isAsap}
                  onChange={handleAsapChange}
                  className="mr-2"
                />
                As soon as possible
              </label>
            </div>
            {pickupTime && (
              <p className="mt-4">
                Selected Pickup Time:{" "}
                {isAsap ? "As soon as possible" : pickupTime}
              </p>
            )}
          </div>
        )}
      </div> */}

      <div className=" pt-6 text-sm text-slate-500 dark:text-slate-400  border-slate-200/70 dark:border-slate-700 ">
        <div className="mt-1 mb-5">
          <div className="mt-1 mb-5">
            <p className="text-sm font-semibold text-slate-700 dark:text-slate-200">
              Add a Tip
            </p>
            <div className="flex space-x-2 mt-2">
              {["none", "10%", "15%", "20%", "custom"].map((tip) => (
                <button
                  key={tip}
                  type="button"
                  onClick={() => handleTipSelection(tip)}
                  className={`px-4 py-2 border rounded ${
                    selectedTip === tip
                      ? "bg-black text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  {tip}
                </button>
              ))}
            </div>
            {selectedTip === "custom" && (
              // <div className="mt-4 flex items-center">
              //   <input
              //     type="number"
              //     value={customTip}
              //     onChange={handleCustomTipChange}
              //     placeholder="enter the tip amount"
              //     className=" p-1 border border-slate-300 dark:border-slate-600 rounded w-full"
              //   />
              // </div>
              <div className="mt-4 flex flex-col items-center">
                <input
                  type="number"
                  value={customTip}
                  onChange={handleCustomTipChange}
                  placeholder="Enter the tip amount"
                  className="p-1 border border-slate-300 dark:border-slate-600 rounded w-full"
                />
                {errorMessage && (
                  <p className="text-red-500 mt-2">{errorMessage}</p>
                )}
              </div>
            )}
          </div>
        </div>
        {NoteContent()}
        <div className="mt-4 flex justify-between py-2.5">
          <span>Subtotal</span>
          <span className="font-semibold text-slate-900 dark:text-slate-200">
            ${subtotal.toFixed(2)}
          </span>
        </div>
        <div>
          {/* {reOrderData ? (
          
            
          ):()} */}
          {taxDisplayList.map((taxDisplay, index) => (
            <div key={index} className="flex justify-between py-2">
              <span>{taxDisplay}</span>
              <span className="font-semibold text-slate-900 dark:text-slate-200">
                ${taxDisplayTotal[index].toFixed(2)}
              </span>
            </div>
          ))}
        </div>
        <div className="mt-1 flex justify-between py-2.5">
          <span>Tip</span>
          {selectedTip ? (
            <span className="font-semibold text-slate-900 dark:text-slate-200">
              ${finalTip(selectedTip)}
            </span>
          ) : (
            <span className="font-semibold text-slate-900 dark:text-slate-200">
              $0.00
            </span>
          )}
        </div>
        <div className="mt-1 flex justify-between py-2.5">
          <span>{storedOrderFeeLabel}</span>
          <span className="font-semibold text-slate-900 dark:text-slate-200">
            ${storedOrderFee.toFixed(2)}
          </span>
        </div>
        <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
          <span>Order total</span>
          <span> ${totalAmount}</span>
        </div>
      </div>
      <ButtonPrimary
        className="justify-center mt-8 w-full "
        disabled={isPaymentProcessing}
      >
        {isPaymentProcessing ? "Payment processing..." : "Confirm order"}
      </ButtonPrimary>
      <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center"></div>
    </div>
  );
};
export default OrderSummary;
