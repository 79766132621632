import { FC, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { postSignUpData } from "services/HomeService";
import { Disclosure } from "@headlessui/react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import Label from "components/Label/Label";
import { State } from "country-state-city";
import Select from "react-select";
import "../../App.css";

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const stateOption = useMemo(() => {
    return State.getAllStates()
      .filter((code) => code.countryCode === "US")
      .map((state) => ({
        label: state.name,
        value: state.isoCode,
      }));
  }, []);
  const cleanPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber) {
      return phoneNumber.replace(/\D/g, "");
    } else {
      return null;
    }
  };
  const formatOptionLabel = ({ label, value }: any, { context }: any) => {
    return context === "value" ? value : label;
  };

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      cardNumber: "",
      cardName: "",
      exprDate: "",
      CVC: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "USA",
      zipCode: "",
    },
  });
  const navigate = useNavigate();

  const submitform = async (data: any) => {
    localStorage.removeItem("SignupData");
    localStorage.removeItem("userData");
    localStorage.removeItem("CustomerInfo");
    localStorage.removeItem("customerAdded");
    localStorage.removeItem("addedPayments");
    localStorage.removeItem("Info");
    localStorage.removeItem("user");
    const basicInfo = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: cleanPhoneNumber(data.phone),
      // address1: data.address1,
      // address2: data.address2,
      address: `${data.address1}${data.address2}`,
      city: data.city,
      state: data.state,
      zipCode: data.zipCode,
      country: data.country,
      password: data.password,
      addressType: "Billing",
    };
    const paymentInfo = {
      cardNumber: data.cardNumber,
      // cardName: data.cardName,
      exprDate: data.exprDate,
      cardCode: data.CVC,
    };
    try {
      const response = await postSignUpData({ basicInfo, paymentInfo });
      const storedata = (response.data as { authNetProfile: any })[
        "authNetProfile"
      ];
      const customerAdded = (response.data as { customerDTO: any })[
        "customerDTO"
      ];
      //const errormsg = storedata.message;
      if (response.data) {
        //const storedata = response.data;
        let paymentArr = [
          {
            billTo: {
              customerId: data.customerId,
              address: data.address1 + " " + data.address2,
              city: data.city,
              country: data.country,
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              phoneNumber: data.phone,
              state: data.state,
              zip: data.zipCode,
            },
            payment: {
              creditCard: {
                cardNumber: "XXXX" + data.cardNumber.slice(-4),
                cardName: data.cardName,
              },
            },
          },
        ];
        let item = {
          "AuthNet Profile Info ": {
            profile: {
              paymentProfiles: paymentArr,
            },
          },
          "Customer Info ": {
            ...(storedata as object),
          },
        };
        console.log("itemtesting", item, storedata, customerAdded);
        localStorage.setItem("userData", JSON.stringify(item));
        localStorage.setItem("SignupData", JSON.stringify(storedata));
        localStorage.setItem("customerAdded", JSON.stringify(customerAdded));
        navigate(`/${merchantname}`);
        window.location.reload();
      }
    } catch (error) {
      console.log(error, "err");
      const message: any = error;
      setErrorMessage(message.response.data.message);

      // if (error.response.data.message === "Failed to create AuthNet customer profile") {
      //    localStorage.setItem('SignupData', JSON.stringify(storedata));
      // }
    }
    //   else if(storedata.message==="Failed to create AuthNet customer profile")
    //   console.log("signUp", basicInfo,paymentInfo,response);
    //   reset();
    // };
  };

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const validateCardNumber = (value: any) => {
    if (!value) {
      return "Card number is required";
    }
    if (value.length < 13) {
      return "Card number must be at least 13 digits";
    }
    if (value.length > 16) {
      return "Card number must be no more than 16 digits";
    } else {
      return true;
    }
  };

  const validateData = (value: any) => {
    if (!/^\d{2}\/\d{2}$/.test(value)) {
      return "Expiration date must be in MM/YY format";
    }
    const [month, year] = value.split("/").map(Number);
    if (month < 1 || month > 12) {
      return "Expiration month must be between 01 and 12";
    }

    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;

    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return "Invalid Expiration date";
    } else {
      return true;
    }
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (phoneNumber) {
      setValue("phone", phoneNumber);
    }
    if (match) {
      return `(${match[1]})${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = localStorage.getItem(MERCHANT_STORAGE_KEY);
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Basil POS Online Ordering</title>
      </Helmet>
      <div className="container mb-14 lg:mb-32">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-3 ">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <form
            onSubmit={handleSubmit(submitform)}
            className="grid grid-cols-1 gap-6"
          >
            <div className="block">
              <div
                className={`${
                  errorMessage &&
                  "bg-red-100 border text-red-700 px-4 py-3 rounded relative"
                }`}
                role="alert"
              >
                <span className="block sm:inline">{errorMessage}</span>
              </div>
              <span className="text-neutral-800 dark:text-neutral-200">
                First Name
              </span>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: "First Name is required",
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    {...field}
                    className="mt-1"
                    placeholder="enter first name"
                  />
                )}
              />
              {errors.firstName && (
                <span className="text-red-500">{errors.firstName.message}</span>
              )}
            </div>
            <div className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Last Name
              </span>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: "Last Name is required",
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    {...field}
                    className="mt-1"
                    placeholder="enter last name"
                  />
                )}
              />
              {errors.lastName && (
                <span className="text-red-500">{errors.lastName.message}</span>
              )}
            </div>
            <div className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email Address
              </span>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Email!!",
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    className="mt-1"
                    placeholder="enter email address"
                  />
                )}
              />
              {errors.email && (
                <span className="text-red-500">{errors.email.message}</span>
              )}
            </div>
            <div className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Phone Number
              </span>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "Phone Number is required",
                  validate: (values) => {
                    const cleaned = values.replace(/\D/g, "");
                    const formet = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                    if (!formet) {
                      return "Invalid Format";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="tel"
                    {...field}
                    className="mt-1"
                    placeholder="(000)000-0000"
                    onChange={(e: any) => {
                      const values = e.target.value;
                      const formatted = formatPhoneNumber(values);
                      setValue("phone", formatted);
                      trigger("phone");
                    }}
                  />
                )}
              />
              {errors.phone && (
                <span className="text-red-500">{errors.phone.message}</span>
              )}
            </div>

            <div className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "password is required",
                }}
                render={({ field }) => (
                  <Input
                    type="password"
                    {...field}
                    className="mt-1"
                    placeholder="enter password"
                  />
                )}
              />
              {errors.password && (
                <span className="text-red-500">{errors.password.message}</span>
              )}
            </div>
            <div className="max-w-lg">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 font-medium text-right bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                      Billing Address
                      {!open ? (
                        <FaAngleDown className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                      ) : (
                        <FaAngleUp className="w-4 h-4 tezxt-slate-600 dark:text-slate-400" />
                      )}
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className="p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300"
                      as="div"
                    >
                      <div
                        className={`mt-6 mb-4 w-full space-y-3 sm:space-y-5`}
                      >
                        <div className="max-w-lg">
                          <Label className="text-sm"> Address 1</Label>
                          <Controller
                            name="address1"
                            control={control}
                            rules={{
                              required: "Address is Required",
                            }}
                            render={({ field }) => (
                              <Input {...field} className="mt-1.5" />
                            )}
                          />
                          {errors.address1 && (
                            <span className="text-red-500">
                              {errors.address1.message}
                            </span>
                          )}
                        </div>
                        <div className="max-w-lg">
                          <Label className="text-sm">Address 2</Label>
                          <Controller
                            name="address2"
                            control={control}
                            render={({ field }) => (
                              <Input {...field} className="mt-1.5" />
                            )}
                          />
                        </div>
                        <div className="flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
                          <div className="flex-1">
                            <Label className="text-sm">City</Label>
                            <Controller
                              name="city"
                              control={control}
                              rules={{
                                required: "City is Required",
                              }}
                              render={({ field }) => (
                                <Input {...field} className="mt-1.5" />
                              )}
                            />
                            {errors.city && (
                              <span className="text-red-500">
                                {errors.city.message}
                              </span>
                            )}
                          </div>
                          <div className="flex-1">
                            <Label className="text-sm">State</Label>
                            <Controller
                              name="state"
                              control={control}
                              rules={{
                                required: "State is Required",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="mt-1.5 input-focus-disable"
                                  options={stateOption}
                                  isSearchable
                                  value={stateOption.find(
                                    (option) => option.value === field.value
                                  )}
                                  onChange={(e: any) => {
                                    setValue("state", e.value);
                                    clearErrors("state");
                                  }}
                                  formatOptionLabel={formatOptionLabel}
                                />
                              )}
                            />
                            {errors.state && (
                              <span className="text-red-500">
                                {errors.state.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
                          <div className="flex-1">
                            <Label className="text-sm">Country</Label>
                            <Controller
                              name="country"
                              control={control}
                              rules={{
                                required: "Country is Required",
                              }}
                              render={({ field }) => (
                                <Input {...field} className="mt-1.5" />
                              )}
                            />
                            {errors.country && (
                              <span className="text-red-500">
                                {errors.country.message}
                              </span>
                            )}
                          </div>
                          <div className="flex-1">
                            <Label className="text-sm">Zipcode</Label>
                            <Controller
                              name="zipCode"
                              control={control}
                              rules={{
                                required: "Zipcode is Required",
                              }}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  className="mt-1.5"
                                  onInput={(e: any) => {
                                    e.target.value = e.target.value
                                      .replace(/\D/g, "")
                                      .slice(0, 6);
                                  }}
                                />
                              )}
                            />
                            {errors.zipCode && (
                              <span className="text-red-500">
                                {errors.zipCode.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="max-w-lg">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 font-medium text-right bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                      Payment Method
                      {!open ? (
                        <FaAngleDown className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                      ) : (
                        <FaAngleUp className="w-4 h-4 tezxt-slate-600 dark:text-slate-400" />
                      )}
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className="p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300"
                      as="div"
                    >
                      <div
                        className={`mt-6 mb-4 w-full space-y-3 sm:space-y-5`}
                      >
                        <div className="max-w-lg">
                          <Label className="text-sm">Card number</Label>
                          <Controller
                            name="cardNumber"
                            control={control}
                            rules={{
                              required: "Card number is required",
                              validate: validateCardNumber,
                            }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                className="mt-1.5"
                                type="tel"
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                }}
                              />
                            )}
                          />
                          {errors.cardNumber && (
                            <span className="text-red-500">
                              {errors.cardNumber.message}
                            </span>
                          )}
                        </div>
                        <div className="max-w-lg">
                          <Label className="text-sm">Name on Card</Label>
                          <Controller
                            name="cardName"
                            control={control}
                            render={({ field }) => (
                              <Input type="text" {...field} className="mt-1" />
                            )}
                          />
                          {errors.cardName && (
                            <span className="text-red-500">
                              {errors.cardName.message}
                            </span>
                          )}
                        </div>
                        <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
                          <div className="sm:w-2/3">
                            <Label className="text-sm">
                              Expiration date (MM/YY)
                            </Label>
                            <Controller
                              name="exprDate"
                              control={control}
                              rules={{
                                required: "date is Required",
                                validate: validateData,
                              }}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  className="mt-1.5"
                                  type="tel"
                                  onInput={(e: any) => {
                                    let inputValue = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    if (
                                      inputValue.length === 1 &&
                                      inputValue > 1
                                    ) {
                                      inputValue = "0" + inputValue;
                                    }
                                    if (inputValue.length >= 2) {
                                      let month = inputValue.slice(0, 2);
                                      if (parseInt(month, 10) > 12) {
                                        month = "12";
                                      }
                                      e.target.value = `${month}/${inputValue.slice(
                                        2,
                                        4
                                      )}`;
                                    } else {
                                      e.target.value = inputValue;
                                    }
                                  }}
                                />
                              )}
                            />

                            {errors.exprDate && (
                              <span className="text-red-500">
                                {errors.exprDate.message}
                              </span>
                            )}
                          </div>
                          <div className="flex-1">
                            <Label className="text-sm">CVC</Label>
                            <Controller
                              name="CVC"
                              control={control}
                              rules={{
                                validate: (values) => {
                                  const cleaned = values.replace(/\D/g, "");
                                  const valid =
                                    cleaned.length === 3 ||
                                    cleaned.length === 4;
                                  if (!valid) {
                                    return "CVC is required";
                                  }
                                  return true;
                                },
                              }}
                              render={({ field }) => (
                                <Input
                                  type="text"
                                  {...field}
                                  onChange={(e) => {
                                    let value = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    if (value.length > 3) {
                                      value = value.slice(0, 4);
                                    }
                                    field.onChange(value);
                                  }}
                                  className="mt-1"
                                />
                              )}
                            />
                            {errors.CVC && (
                              <span className="text-red-500">
                                {errors.CVC.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link className="text-green-600" to={`/${merchantname}/login`}>
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
