
// import MyRouter from "routers/index";
import { ProductCartContextProvider } from "./contaxt/CartProductContext"
import { Helmet, HelmetProvider } from "react-helmet-async";
import MyRoutes from "routers/index";

function App() {
  const logo = localStorage.getItem("onlineOrderSettingsLogo");
  return (
    <HelmetProvider>
      <Helmet>
        <title>Basil POS Online Ordering</title>
        <meta
          name="description"
          content="Basil POS Online Ordering"
        />
        <link rel="icon" href={`${logo}`} type="image/x-icon"></link>
      </Helmet>
      <ProductCartContextProvider>
      {/* MAIN APP */}
        <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
          <MyRoutes />
        </div>
      </ProductCartContextProvider>

    </HelmetProvider>
  );
}

export default App;
