
import { Controller,useFormContext  } from 'react-hook-form'; // Assuming you're using react-hook-form
import Select from "react-select";// Replace with actual imports from your component library
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { State } from "country-state-city";
import React, { useMemo, useState,useEffect } from "react";
interface BillingAddressFormProps {
  control: any; // Replace with the type for your form control
    errors: any;
  setValue:any,
    clearErrors:any,  // Replace with the type for your form errors
}
  
const BillingAddressForm: React.FC<BillingAddressFormProps> = ({ control, errors, setValue, clearErrors }) => {
    const formatOptionLabel = ({ label, value }: any, { context }: any) => {
    return context === "value" ? value : label;
    };
    const stateOption = useMemo(() => {
    return State.getAllStates()
      .filter((code: any) => code.countryCode === "US")
      .map((state: any) => ({
        label: state.name,
        value: state.isoCode,
      }));
  }, []);
    //const { clearErrors,setValue } = useFormContext();
    return (
        <div className={`mt-6 mb-4 space-y-3 sm:space-y-5`}>
            <h1 className="text-1xl font-bold">Billing Address</h1>
            <div className="max-w-lg">
                <Label className="text-sm"> Address 1</Label>
                <Controller
                    name="address1"
                    control={control}
                    rules={{
                        required: "Address is Required",
                    }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            className="mt-1.5"
                            onInput={(e: any) => e.target.value}
                        />
                    )}
                />
                {errors.address1 && (
                    <span className="text-red-500">
                        {errors.address1.message}
                    </span>
                )}
            </div>
            <div className="max-w-lg">
                <Label className="text-sm">Address 2</Label>
                <Controller
                    name="address2"
                    control={control}
                    render={({ field }) => (
                        <Input
                            {...field}
                            className="mt-1.5"
                            onInput={(e: any) => e.target.value}
                        />
                    )}
                />
            </div>
            <div className="flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
                <div className="flex-1">
                    <Label className="text-sm">City</Label>
                    <Controller
                        name="city"
                        control={control}
                        rules={{
                            required: "City is Required",
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                className="mt-1.5"
                                onInput={(e: any) => e.target.value}
                            />
                        )}
                    />
                    {errors.city && (
                        <span className="text-red-500">
                            {errors.city.message}
                        </span>
                    )}
                </div>
                <div className="flex-1">
                    <Label className="text-sm">State</Label>
                    <Controller
                        name="state"
                        control={control}
                        rules={{
                            required: "State is Required",
                        }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                className="mt-1.5 input-focus-disable"
                                options={stateOption as any}
                                isSearchable
                                value={stateOption.find(
                                    (option: any) => option.value === field.value
                                )}
                                onChange={(e: any) => {
                                    setValue("state", e.value);
                                    clearErrors("state");
                                }}
                                formatOptionLabel={formatOptionLabel}
                            />
                        )}
                    />
                    {errors.state && (
                        <span className="text-red-500">
                            {errors.state.message}
                        </span>
                    )}
                </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
                <div className="flex-1">
                    <Label className="text-sm">Country</Label>
                    <Controller
                        name="country"
                        control={control}
                        rules={{
                            required: "Country is Required",
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                className="mt-1.5"
                                onInput={(e: any) => e.target.value}
                            />
                        )}
                    />
                    {errors.country && (
                        <span className="text-red-500">
                            {errors.country.message}
                        </span>
                    )}
                </div>
                <div className="flex-1">
                    <Label className="text-sm">Zipcode</Label>
                    <Controller
                        name="zip"
                        control={control}
                        rules={{
                            required: "Zipcode is Required",
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                className="mt-1.5"
                                onInput={(e: any) => {
                                    e.target.value = e.target.value
                                        .replace(/\D/g, "")
                                        .slice(0, 6);
                                }}
                            />
                        )}
                    />
                    {errors.zip && (
                        <span className="text-red-500">
                            {errors.zip.message}
                        </span>
                    )}
                </div>
            </div>
        </div>
    )
}
export default BillingAddressForm;