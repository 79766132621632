import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.svg";
import logoLightImg from "images/logo-light.svg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "flex-shrink-0",
}) => {
  const [logo, setLogo] = React.useState<string | null>(null);
  const [merchantName, setMerchantName] = React.useState<string | null>(null);
  useEffect(() => {
    const merchantname = localStorage.getItem("merchantname");
    setMerchantName(merchantname);
    const logo = localStorage.getItem("onlineOrderSettingsLogo");
  setLogo(logo);
  })
  
  console.log(logo,"logo")
  return (
    <Link
      to={`/${merchantName}`}
      className={`ttnc-logo inline-block text-slate-600 ${className}`}
    >
      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {logo ? (
        <img
          className={`block max-h-16 sm:max-h-14 ${logo ? "dark:hidden" : ""}`}
          src={logo}
          alt="Logo"
         
        />
      ) : (
        "Logo Here"
      )}
      {/* {logo && (
        <img
          className="hidden max-h-8 sm:max-h-6 dark:block"
          src={logo}
          alt="Logo-Light"
        />
      )} */}
    </Link>
  );
};

export default Logo;
