import Label from "components/Label/Label";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import appConfig from "configs/app.config";
import React, { useEffect, useState } from "react";
import BaseService, { getHeaders } from "services/BaseService";
import axios from "axios";
import OrderDetails from "./OrderDetails";

import { useNavigate } from "react-router-dom";
import Pagination from "shared/Pagination/Pagination";
import { getMenuScheduleData } from "services/HomeService";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
interface Order {
  orderNo: number;
  date: string;
  totalAmount: number;
  subTotal: number;
  tax: number;
  tip: number;
  status: string;
  orderItems: [
    {
      name: string;
      quantity: number;
      price: number;
    }
  ];
}
const AccountOrder = () => {
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const userDataString = localStorage.getItem("userData");
  const SignupData = localStorage.getItem("SignupData");
  const CustomerAdded=localStorage.getItem("customerAdded");
  const Info=localStorage.getItem("CustomerInfo");
  const [userData, setUserData] = useState<any>(null);
  const merchantname = localStorage.getItem("merchantname");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [manuScheludeData, setManuScheludeData] = useState<any[]>([]);
  const navigate = useNavigate();
  const convertToLocalTime = (time: string) => {
    console.log("time", time);
    const [hours, minutes] = time.split(":").map(Number);
    const utcDate = new Date();
    utcDate.setUTCHours(hours, minutes);

    const localTime = new Intl.DateTimeFormat("default", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "America/Chicago", // replace this with the desired timezone if needed
    }).format(utcDate);
    console.log("localTime", localTime);
    return localTime;
  };
  const handlereOrder = (item: any) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const findTodayDate = manuScheludeData.filter(
      (_item, index) => _item.menuDays === days[new Date().getDay()]
    );
    console.log(
      findTodayDate,
      convertToLocalTime(findTodayDate[0].fromTime),
      findTodayDate.length > 0,
      manuScheludeData,
      new Date().getDay(),
      ";llllll1"
    );
    const pad = (num: number) => (num < 10 ? "0" + num : num);

    // Get current time in HH:mm format
    const now = new Date();
    const hour = pad(now.getHours());
    const minute = pad(now.getMinutes());
    const presentDate = `${hour}:${minute}`;

    // Extract times from schedule data (assumed format HH:mm)
    const fromTime =
      findTodayDate.length > 0
        ? convertToLocalTime(findTodayDate[0].fromTime).slice(0, 5)
        : "00:00";
    const toTime =
      findTodayDate.length > 0
        ? convertToLocalTime(findTodayDate[0].toTime).slice(0, 5)
        : "00:00";
    const checkTime = fromTime < presentDate && toTime > presentDate;
    console.log("checkTime", checkTime, fromTime, presentDate, toTime);

    if (checkTime) {
      navigate(`/${merchantname}/checkout`, { state: { item } });
    } else {
      toast.custom(
        (t) => (
          <Transition
            appear
            show={t.visible}
            className="p-4 max-w-md w-full bg-red-100 dark:bg-red-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-red-500/50 dark:ring-red-500/10 text-red-900 dark:text-red-200"
            enter="transition-all duration-150"
            enterFrom="opacity-0 translate-x-20"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-20"
          >
            <p className="block text-base font-semibold leading-none">
              Failed to Checkout Order
            </p>
            <div className="border-t border-red-200 dark:border-red-700 my-4" />
            Sorry, the restaurant is currently closed
          </Transition>
        ),
        { position: "top-right", id: "nc-product-notify", duration: 3000 }
      );
    }
  };

  useEffect(() => {
    const getMenuSchedule = async () => {
      try {
        const response: any = await getMenuScheduleData();
        setManuScheludeData(response.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    getMenuSchedule();
  }, []);

  const handleViewOrder = (order: Order) => {
    setSelectedOrder(order);
    setModalOpen(true);
  };
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedOrders = orders.slice(startIndex, endIndex);
  useEffect(() => {
    const fetchDataFromApi = async (page: number) => {
      try {
        // Make the API call to fetch data
        if (SignupData && CustomerAdded && userDataString) {
          const response = JSON.parse(SignupData);
          const customerResponse = JSON.parse(CustomerAdded);
          console.log(response, customerResponse, "checkkkkks");
          setUserData(customerResponse);
          const apiUrl = `${appConfig.apiPrefix}/onlineOrder/history`;

          const headers = getHeaders();

          const mid = merchantname;
          const customerId = customerResponse.customerId;

          const responses = await axios.get(apiUrl, {
            headers: {
              MID: mid,
              customerId: customerId,
            },
          });
          console.log("reset", responses.data);
          if (!responses.data) {
            throw new Error("Network response was not ok");
          }

          // const data: Order[] = await response.data;
          const reversedOrders = responses.data.reverse();
          setOrders(reversedOrders);
          setTotalItems(responses.data.length);
        }

        
        else if (userDataString &&Info && !SignupData) {
          const response = JSON.parse(Info);
          // Assuming the response is an object with a "Customer Info" property
          setUserData(response);
          console.log(
            response,
            "userdataall"
          );
          const apiUrl = `${appConfig.apiPrefix}/onlineOrder/history`;

          const headers = getHeaders();

          const mid = merchantname;
          const customerId = response.customerId;

          const responses = await axios.get(apiUrl, {
            headers: {
              MID: mid,
              customerId: customerId,
            },
          });
          console.log("reset", responses.data);
          if (!responses.data) {
            throw new Error("Network response was not ok");
          }

          // const data: Order[] = await response.data;
          const reversedOrders = responses.data.reverse();
          setOrders(reversedOrders);
          setTotalItems(responses.data.length);
        }
       
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (!userData ) {
      fetchDataFromApi(currentPage);
      // fetchOrders();
    }
  }, [userDataString, SignupData, currentPage,Info]);

  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">Order History</h2>
          <div>
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>Error: {error}</p>
            ) : orders.length === 0 ? (
              <p>No orders found.</p>
            ) : (
              paginatedOrders.map((order) => (
                <div
                  key={order.id}
                  className="border rounded-lg p-4 mb-4 cursor-pointer hover:cursor-pointer"
                  onClick={() => handleViewOrder(order)}
                >
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-xl font-bold">
                      Order #{order.orderNo}
                    </h3>
                    <p className="font-semibold">Total: ${order.totalAmount}</p>
                  </div>
                  {/* <p className="mb-1">Date: {order.date}</p> */}
                  <h4 className="text-lg font-semibold mt-4 mb-2">Items:</h4>
                  <ul className="list-disc pl-5">
                    {order.orderItems.map((item: any, index: any) => (
                      <li key={index} className="mb-2 flex items-center">
                        <img
                          src={item.item.imageUrl}
                          alt={item.item.name}
                          className="w-12 h-12 mr-4 object-cover rounded-lg"
                        />
                        <span>
                          {item.item.name} - {item.quantity}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <div className="mt-4 flex space-x-4 justify-end">
                    <button
                      className="mt-4 bg-custom-green text-white font-normal py-2 px-4 rounded"
                      onClick={() => handlereOrder(order)}
                    >
                      Reorder
                    </button>
                    {/* <button
                      onClick={() => handleViewOrder(order)}
                      className="mt-4 bg-custom-green text-white font-normal py-2 px-4 rounded"
                    >
                      View
                    </button> */}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </CommonLayout>
      {selectedOrder && (
        <OrderDetails
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          order={selectedOrder}
          handlereOrder={handlereOrder}
        />
      )}
      {
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(totalItems / itemsPerPage)}
          onPageChange={setCurrentPage}
          loading={loading}
        />
      }
    </div>
  );
};

export default AccountOrder;
